import HttpClient from './httpclient.js';
const PATH = "/v1/oauth2";

export async function login(payload) {
    let response = await HttpClient("post", PATH + "/login", { payload: payload });
    return response;
}
export async function failedAttempts(){
    let response = await HttpClient("get", "/v1/info" + "/failedAttempts");
    return response;
}