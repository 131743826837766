import React from 'react';
import '../styles/Login.css';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as LoginRequest from '../../util/loginRequest';
import { Notification } from 'joonas.ninja-frontend-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LoadingSpinner from '../view/LoadingSpinner.jsx';

class Attempts extends React.Component {
    render() {
        if (this.props.loading) {
            return (
                <LoadingSpinner />
            );
        }
        else {
            return (
                <div>
                    {this.props.count}
                </div>
            );
        }
    }
}

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            notificationMessage: "",
            notification: false,
            invalidCredentials: false,
            loginAttempts: 0,
            loadingAttempts: false
        }
    }
    async componentDidMount() {
        await this.refreshAttempts();
    }
    refreshAttempts = async () => {
        try {
            this.setState({ loadingAttempts: true });
            let response = await LoginRequest.failedAttempts();
            this.setState({ loginAttempts: response.data.count, loadingAttempts: false });
        } catch (error) {
            this.setState({ notification: true, notificationMessage: "Error loading attempts.", loadingAttempts: false });
        }
    }
    handleClose = () => {
        this.setState({ notification: false })
    }
    valueChanged = (field, event) => {
        this.setState({ [field]: event.target.value });
    }
    loginButton = async () => {
        try {
            let response = await LoginRequest.login({ username: this.state.username, password: this.state.password });
            if (response.status === 401) {
                await this.refreshAttempts();
                this.setState({ notification: true, notificationMessage: "Access denied." });
            }
            else {
                window.location = response.data.url;
            }
        } catch (error) {
            this.setState({ notification: true, notificationMessage: "Error in login." });
        }
    }
    render() {
        return (
            <div>
                <Paper className="login-form">
                    <AppBar position="static" color="primary">
                        <Toolbar>
                            <Typography variant="h5" color="inherit">
                                joonas.ninja gateway
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className="login-form__title-content">
                        {"Welcome. Please log in."}
                    </div>
                    <form className="login-form__body">
                        <TextField
                            className="login-form__field"
                            label="Username"
                            value={this.state.username}
                            onChange={(event) => { this.valueChanged('username', event) }}
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            className="login-form__field"
                            label="Password"
                            type="password"
                            value={this.state.password}
                            onChange={(event) => { this.valueChanged('password', event) }}
                            margin="normal"
                            variant="outlined"
                        />
                    </form>
                    <div className="login-form__footer">
                        <Button className="login-form__button" onClick={this.loginButton} variant="contained" color="primary">
                            Login
                        </Button>
                    </div>
                </Paper>
                <div className="failed-login-counter">
                    Failed login attempts:
                            <p />
                    <Attempts count={this.state.loginAttempts} loading={this.state.loadingAttempts} />
                </div>
                <Notification open={this.state.notification} onClose={this.handleClose} notificationMessage={this.state.notificationMessage} />
            </div>
        );
    }
}

export default Login;
