import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../styles/LoadingSpinner.css';

class LoadingSpinner extends React.Component {
    render() {
        return (
            <div>
                <CircularProgress />
            </div>
        );
    }
}

export default LoadingSpinner