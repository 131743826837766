import Axios from 'axios';
import Const from './const.js';

export default async function apiRequest(method, path, options) {
    try {
        let payload;
        if (options) {
            payload = options.payload;
        }

        let request = {
            method: method,
            url: Const.API_PATH + path,
            validateStatus: function (status) {
                if(status === 401 || status === 403){
                    return true;
                }
                return status >= 200 && status <= 300
            }
        }

        if (payload) {
            request.data = payload;
        }
        let response = await Axios(request);
        return response;
    } catch (error) {
        throw error;
    }
} 