import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Switch, Route, Redirect} from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom'
import Primary from '@material-ui/core/colors/green';
import Secondary from '@material-ui/core/colors/blue';
import Login from './controller/Login';
import './styles/App.css';

const theme = createMuiTheme({
  palette: {
    primary: Primary,
    secondary: Secondary
  },
  typography: {
    useNextVariants: true,
  }
});

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/ui">
        <MuiThemeProvider theme={theme}>
          <Switch>
            <Route exact path="/" component={() => (<Redirect to="/login" />)} />
            <Route path="/login" component={Login} />
          </Switch>
        </MuiThemeProvider>
      </BrowserRouter>
    );
  }
}

export default App;
